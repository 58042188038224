import React, { useState, useEffect, useContext } from 'react';
import { Container, Navbar, Nav, Form, FormControl, InputGroup, Dropdown, DropdownButton, Button, Badge, Row, Col, Modal, NavDropdown } from 'react-bootstrap';
import './Header.css';
import { IoCalendarClearOutline, IoCartOutline, IoHeartOutline, IoMenuSharp, IoPersonOutline, IoSearchOutline } from "react-icons/io5";
import logoImage from '../../assets/images/logo.png';
import { CartContext } from '../../Utils/CartContext';
import { WishlistContext } from '../../Utils/WishlistContext';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { FaCalendar, FaFacebook, FaInstagram, FaTimes, FaWhatsapp } from 'react-icons/fa';
import { TbBrandGoogleMaps } from 'react-icons/tb';
import { AiFillYoutube, AiOutlineMail, AiOutlineWhatsApp } from 'react-icons/ai';
import { TfiHeadphoneAlt, TfiInfo, TfiInfoAlt } from 'react-icons/tfi';
import WOWComponent from '../../Utils/WOW';
import SecureLS from "secure-ls";
import Marquee from "react-fast-marquee";

function Header() {
    const { cartCount, cartTotal } = useContext(CartContext);
    const { wishlistCount } = useContext(WishlistContext); // Use wishlist context

    const [iconColor, setIconColor] = useState('white');
    const [searchInput, setSearchInput] = useState('');

    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    const handleInputChange = (e) => {
        setSearchInput(e.target.value);
    };

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (searchInput != '') {
            navigate(`/search/${searchInput}`, {
                state: {
                    searchInput
                }
            });
        }
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        // Close the sidebar first
        handleSidebarClose();

        // After closing the sidebar, show the logout confirmation modal
        setTimeout(() => {
            setShow(true);
        }, 300); // Adjust the delay if necessary
    };


    useEffect(() => {
        const handleScroll = () => {
            const currentScrollTop = window.pageYOffset;
            if (currentScrollTop > lastScrollTop && currentScrollTop > 1 * window.innerHeight / 100) {
                setIsNavbarVisible(false);
            } else {
                setIsNavbarVisible(true);
            }
            setLastScrollTop(currentScrollTop);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollTop]);

    const [categories, setCategories] = useState([]);
    const baseUrl = 'https://karstar.org/'

    useEffect(() => {
        getCategories();
    }, [])

    const getCategories = async () => {
        try {
            const response = await axios.get('https://karstar.org/api/client_api/productCategories/index.php');
            setCategories(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const generateSlug = (name) => {
        return name.replace(/ /g, '-').replace(/[^\w-]+/g, '');
    }

    const adjustPadding = () => {
        const header = document.querySelector('.header'); // Adjust the selector as needed
        const section = document.querySelector('.sectionView');

        if (header && section) {
            const headerHeight = header.offsetHeight;
            section.style.paddingTop = `${headerHeight + 16}px`; // Adjust the additional padding as needed
        }
    };

    useEffect(() => {
        adjustPadding();
        window.addEventListener('resize', adjustPadding);

        return () => window.removeEventListener('resize', adjustPadding);
    }, []);

    // Initialize SecureLS
    const ls = new SecureLS({ encodingType: 'aes' });

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userName, setUserName] = useState('');

    useEffect(() => {
        // ls.removeAll();
        // Retrieve the stored email and name using SecureLS
        const email = ls.get('userEmail');
        const name = ls.get('userName');

        if (email && name) {
            setIsLoggedIn(true);
            setUserName(name);
        }
    }, []);


    const SignInLink = () => {
        const location = useLocation();

        const handleSignInClick = () => {
            // Store the current path in sessionStorage
            sessionStorage.setItem('redirectPath', location.pathname);
        };

        return (
            <Link
                to='/auth/login'
                className='text-decoration-none text-black d-flex align-items-center justify-content-center'
                onClick={handleSignInClick} // Call this function on click
            >
                <div className="icon-text-container">
                    <IoPersonOutline size={'22px'} color='#000' className="icon" />
                    <div className='flex-column d-none d-lg-flex d-md-flex'>
                        <span className="text-small text-start">Sign in</span>
                        <span className="text">Guest user</span>
                    </div>
                </div>
            </Link>
        );
    };


    const UserDropdown = () => {
        return (
            // <div className="dropdown d-flex">
            //     <div className="icon-text-container" style={{ cursor: 'pointer' }}>
            //         <IoPersonOutline size={'22px'} color='#000' className="icon" />
            //     </div>
            //     <div className="dropdown-content">
            //         <a href="#" className="text-center text-danger">{userName}</a>
            //         <hr style={{ margin: '0' }} className="text-danger" />
            //         <div className="dropdown-options">
            //             <a href="myprofile">
            //                 <i className="fa-solid fa-user blue"></i>
            //                 My Profile
            //             </a>
            //         </div>
            //         <div className="dropdown-options">
            //             <a href="myorders">
            //                 <i className="fa-solid fa-cart-shopping blue"></i>
            //                 My Orders
            //             </a>
            //         </div>
            //         <div className="dropdown-options">
            //             <a href="myaddress">
            //                 <i className="fas fa-map-marker-alt blue"></i>
            //                 Manage Addresses
            //             </a>
            //         </div>
            //         <div className="dropdown-options">
            //             <a href="" data-bs-toggle="modal" data-bs-target="#confirmlogoutmodal">
            //                 <i className="fa fa-sign-out blue" aria-hidden="true"></i>
            //                 Logout
            //             </a>
            //         </div>
            //     </div>
            // </div>
            <Link
                to='/account'
                className='text-decoration-none text-black d-flex align-items-center justify-content-center'
            >
                <div className="icon-text-container">
                    <IoPersonOutline size={'22px'} color='#000' className="icon" />
                </div>
            </Link>
        );
    };

    const handleMenuClick = () => {
        setSidebarOpen(true);
    };

    const handleSidebarClose = () => {
        setSidebarOpen(false);
    };

    const handleLogout = async () => {
        try {
            // Send the POST request to the logout endpoint
            const response = await axios.post('https://karstar.org/api/client_api/authentication/logout/index.php');

            // Check if the response indicates success
            if (response.data.success) {
                // Remove user data from SecureLS
                ls.remove('userEmail');
                ls.remove('userName');

                // Update state to reflect logout
                setIsLoggedIn(false);
                setUserName('');

                // Redirect to home page
                window.location.href = 'https://karstar.org';
            } else {
                // Handle the case where the server response is not successful
                alert('Error logging out, please try again');
            }
        } catch (error) {
            // Handle any errors during the request
            console.error('Error logging out:', error);
            alert('Error logging out: Please try again.');
        }
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => setIsOpen(!isOpen);
    const handleMouseEnter = () => setIsOpen(true);
    const handleMouseLeave = () => setIsOpen(false);

    return (
        <div>
            <header className={`custom-header ${isNavbarVisible ? '' : 'hidden'}`}>
                <div style={{ borderBottom: "1px solid #DDDDDD" }} className={`py-0 header-top menu-navbar`}>
                    <Container>
                        <Row className="align-items-center">
                            <Col xs={12} lg={4} sm={4} md={2} className="p-0">
                                <div className="social-media d-flex">
                                    {/* <a
                                        className="px-2 text-decoration-none"
                                        href="https://api.whatsapp.com/send/?phone=919036113046&text&type=phone_number&app_absent=0"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaWhatsapp color="#CC0001" size={18} />
                                    </a> */}
                                    <a
                                        className="px-2 text-decoration-none"
                                        href="https://www.facebook.com/people/Hotstarr/100076573480758/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaFacebook color="#CC0001" size={18} />
                                    </a>
                                    <a
                                        className="px-2 text-decoration-none"
                                        href="https://www.instagram.com/Hotstarr2022/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaInstagram color="#CC0001" size={18} />
                                    </a>
                                    <a
                                        className="px-2 text-decoration-none"
                                        href="https://maps.app.goo.gl/g31bRXvAch62bMbC8"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <TbBrandGoogleMaps color="#CC0001" size={18} />
                                    </a>
                                    <a
                                        className="px-2 text-decoration-none"
                                        href="https://www.youtube.com/channel/UCqRFzmq55EPke8gUOcqqarA/featured"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <AiFillYoutube color="#CC0001" size={18} />
                                    </a>
                                </div>
                            </Col>
                            <Col xs={12} lg={8} sm={8} md={10} className="p-0 d-flex justify-content-end">
                                <div className="d-flex flex-row align-items-center p-2">
                                    <IoCalendarClearOutline color="#CC0001" size={15} />
                                    <p className="text-start text-capitalize mb-0 ms-1 header-top-text">Since 2016</p>
                                </div>
                                <div className="d-flex flex-row align-items-center p-2">
                                    <AiOutlineMail color="#CC0001" size={15} />
                                    <p className="text-start mb-0 ms-1 header-top-text" >
                                        <a
                                            href="mailto:info@hotstarr.co.in"
                                            className="text-decoration-none text-black"
                                            target="_blank"
                                        >
                                            info@hotstarr.co.in
                                        </a>
                                    </p>
                                </div>
                                <div className="d-flex flex-row align-items-center p-2">
                                    <TfiHeadphoneAlt color="#CC0001" size={15} />
                                    <p className="text-start mb-0 ms-1 header-top-text"
                                    >
                                        <a
                                            href="tel:9036113046"
                                            className="text-decoration-none text-black"
                                            target="_blank"
                                        >
                                            (+91) 888 485 5855
                                        </a>
                                    </p>
                                </div>
                                <div className="d-flex flex-row align-items-center p-2">
                                    <TfiInfoAlt color="#CC0001" size={15} />
                                    <p className="text-start mb-0 ms-1 header-top-text"
                                    >
                                        <a
                                            href="tel:9036113046"
                                            className="text-decoration-none text-black"
                                            target="_blank"
                                        >
                                            (+91) 903 611 3046
                                        </a>
                                    </p>
                                </div>
                                <div className="d-flex flex-row align-items-center p-2 header-top-text">
                                    <AiOutlineWhatsApp color="#CC0001" size={15} />
                                    <p className="text-start text-small mb-0 ms-1 header-top-text">
                                        <a
                                            href="https://api.whatsapp.com/send/?phone=919036113046&text&type=phone_number&app_absent=0"
                                            className="text-decoration-none text-black"
                                            target="_blank"
                                        >
                                            (+91) 903 611 3046
                                        </a>
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className='bg-dark d-flex py-1'>
                    <Marquee pauseOnHover autoFill speed={'80'}>
                        <div className='me-5' >
                            <p className='text-white mb-0' style={{ fontSize: '12px' }}>Shipping across India!</p>
                        </div>
                        <div className='me-5'>
                            <p className='text-white mb-0' style={{ fontSize: '12px' }}>Shippings are through <strong>Professional Couriers</strong></p>
                        </div>
                    </Marquee>
                </div>
                <div className={`top-header ${isNavbarVisible ? '' : 'bg-light shadow'}`}>
                    <Container className="d-flex align-items-center">
                        <div className="logo" style={{ flexBasis: '20%' }}>
                            <Navbar.Brand className='d-flex flex-column'>
                                <Link to="/">
                                    <img
                                        src={logoImage}
                                        width="auto"
                                        height="65"
                                        alt="Karstar"
                                    />
                                </Link>
                            </Navbar.Brand>
                        </div>
                        <div className="d-flex align-items-center justify-content-end" style={{ flexBasis: '80%' }}>
                            <div className="search-bar d-none d-lg-block">
                                <Form onSubmit={handleSubmit}>
                                    <InputGroup>
                                        <FormControl
                                            style={{ border: '1px solid #000', padding: '8px 10px', width: '20rem', color: '#CC0001' }}
                                            aria-label="Search Products"
                                            placeholder="Search Products"
                                            value={searchInput}
                                            onChange={handleInputChange}
                                        />
                                        <Button style={{ borderColor: '#000', borderWidth: '1px', background: '#CC0001' }}
                                            onMouseEnter={() => setIconColor('white')}
                                            onMouseLeave={() => setIconColor('white')}
                                            type='submit'
                                            className='d-flex justify-content-center align-items-center'
                                        >
                                            <IoSearchOutline
                                                size={'20px'}
                                                color={iconColor}
                                                className="icon searchIcon"
                                            />
                                            Search
                                        </Button>
                                    </InputGroup>
                                </Form>
                            </div>
                            <div className='d-flex'>
                                {isLoggedIn ? <UserDropdown /> : <SignInLink />}
                                <Link to='Wishlist' className='text-decoration-none d-flex align-items-center justify-content-center'>
                                    <div className="icon-text-container">
                                        <IoHeartOutline size={'22px'} color='#000' className="icon" />
                                        <span className='icon-badge wishlist-badge'> {wishlistCount} </span>
                                    </div>
                                </Link>
                                <Link to='Cart' className='text-decoration-none d-flex align-items-center justify-content-center'>
                                    <div className="icon-text-container">
                                        <IoCartOutline size={'22px'} color='#000' className="icon" />
                                        <span className='icon-badge cart-badge'> {cartCount} </span>
                                    </div>
                                </Link>
                                <div className="icon-text-container d-none d-lg-block d-md-block">
                                    <div className='d-flex flex-column'>
                                        <span className="text-small text-start">Cart total</span>
                                        <span className="text fw-bold">₹ {cartTotal}</span>
                                    </div>
                                </div>
                                <div className='text-decoration-none d-flex d-lg-none align-items-center justify-content-center'>
                                    <div className="icon-text-container">
                                        <IoMenuSharp size={'22px'} color='#000' className="icon"
                                            onClick={() => handleMenuClick()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                <Navbar style={{ background: '#CC0001', height: 'auto', zIndex: -1 }} className={`menu-navbar py-0 my-0 ${isNavbarVisible ? '' : 'bottom-hidden'}`}>
                    <Container>
                        <Navbar id="basic-navbar-nav  py-0 my-0 d-flex" style={{ height: 'auto' }}>
                            <Nav className="me-auto text-white">
                                <Link to="/" className="nav-link text-white fw-light bottomNavbarCategories  py-0 my-0">Home</Link>
                                {/* <Link to="/collections" className="nav-link text-white fw-light me-2 bottomNavbarCategories  py-0 my-0">Products</Link> */}
                                <div
                                    className="custom-dropdown py-0 my-0"
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <button className="btn btn-link nav-link text-decoration-none bottomNavbarCategories text-white py-0 my-0 fw-light" onClick={toggleDropdown}>
                                        Products
                                    </button>
                                    <div className={`dropdown-menu ${isOpen ? 'show' : ''}`}>
                                    {categories
                                        .slice() // Create a shallow copy of the array
                                        .sort((a, b) => {
                                            const priority = ["All Products", "Instant Geysers", "Portable Washing Machine","Semi Automatic washing Machine", "High Pressure Washers", "Vaccum Cleaners", "Water Pumps", "Mixers and Wet Grinders","Gas Stoves", "Chimneys", "Air coolers", "Shoe Racks", "Korai Mat", "Sofa cum Beds"];
                                            const aPriority = priority.indexOf(a.name);
                                            const bPriority = priority.indexOf(b.name);

                                            // Prioritize the categories in the priority array
                                            if (aPriority !== -1 && bPriority !== -1) {
                                                return aPriority - bPriority;
                                            }
                                            if (aPriority !== -1) return -1; // a has priority
                                            if (bPriority !== -1) return 1; // b has priority

                                            // Default alphabetical sorting for others
                                            return a.name.localeCompare(b.name);
                                        })
                                        .map((item, index) => (
                                            <Link key={index} className="dropdown-item" to={`/collections/${generateSlug(item.name)}`}>{item?.name}</Link>
                                        ))}
                                    </div>
                                </div>
                                <Link to="/aboutus" className="nav-link text-white fw-light me-2 bottomNavbarCategories  py-0 my-0">About Us</Link>
                                <Link to="/contactus" className="nav-link text-white fw-light me-2 bottomNavbarCategories py-0 my-0">Contact Us</Link>
                                <Link to="/store-locator" className="nav-link text-white fw-light me-2 bottomNavbarCategories py-0 my-0">Store Locator</Link>
                                <Link to="/track-order" className="nav-link text-white fw-light me-2 bottomNavbarCategories py-0 my-0">Track Order</Link>
                            </Nav>
                        </Navbar>
                    </Container>
                </Navbar>
            </header>

            {/* Backdrop */}
            {(isSidebarOpen) && (
                <div className="backdrop show" onClick={handleSidebarClose}></div>
            )}

            {/* Menu Sidebar */}
            <aside className={`wrap-sidebar js-sideorderbar ${isSidebarOpen ? 'show-sidebar' : ''}`}>
                <div className="sidebar p-t-22 p-b-25">
                    <div className="d-flex justify-content-between align-items-center " style={{ position: 'sticky', top: 0, zIndex: 1, }}>
                        <div className="text-dark mobile-menu-toggle-icon f12 fw-bold">
                            <div className="logo" style={{ flexBasis: '10%' }}>
                                <Navbar.Brand className='d-flex justify-content-center align-items-center'>
                                    <Link to="/">
                                        <img
                                            src={logoImage}
                                            width="auto"
                                            height="50"
                                            className="d-inline-block align-top"
                                            alt="stylespotlightco"
                                        />
                                    </Link>
                                </Navbar.Brand>
                            </div>
                        </div>
                        <div className="mobile-menu-toggle-icon js-hide-sideordertrackbar" onClick={handleSidebarClose}>
                            <FaTimes size={'22px'} color='#000' className="icon" />
                        </div>
                    </div>
                    <div className="dropdown px-3">
                        <div className="dropdown-options py-2">
                            <Link to="/" className='text-black'>
                                Home
                            </Link>
                        </div>
                        <div className="dropdown-options py-2">
                            <Link to='/collections' className='text-black'>
                                Products
                            </Link>
                        </div>
                        <div className="dropdown-options py-2">
                            <Link to='/aboutus' className='text-black'>
                                About Us
                            </Link>
                        </div>
                        <div className="dropdown-options py-2">
                            <Link to='/contactus' className='text-black'>
                                Contact Us
                            </Link>
                        </div>
                        <div className="dropdown-options py-2">
                            <Link to='/store-locator' className='text-black'>
                                Store Locator
                            </Link>
                        </div>
                        <div className="dropdown-options py-2">
                            <Link to='/track-order' className='text-black'>
                                Track Order
                            </Link>
                        </div>
                    </div>
                </div>
            </aside>
            {/* Logout Confirmation Modal */}
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title>Confirm Logout</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to log out?</Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="dark" onClick={handleLogout}>
                        Logout
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Header;
