import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BsTruck } from "react-icons/bs";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { GoShieldCheck } from "react-icons/go";
import { IoStorefrontOutline } from "react-icons/io5";

const Features = () => {
    return (
        <div style={{ background: '#CC0001', padding: '1rem' }} className='my-5'>
            <Container className="my-2">
                <Row className="text-center">
                    <Col md={3} sm={12} className='d-flex flex-column flex-md-row align-items-center justify-content-center my-4'>
                        <BsTruck size={50} color='white' />
                        <div className='text-center text-md-start mx-2 mt-1'>
                            <h6 className="my-0 text-uppercase text-white">Shipping across India</h6>
                            <p className='mb-0 text-white' style={{ fontSize: '0.8rem' }}>We deliver all over India</p>
                        </div>
                    </Col>
                    <Col md={3} sm={12} className='d-flex flex-column flex-md-row align-items-center justify-content-center my-4'>
                        <IoStorefrontOutline size={50} color='white'/>
                        <div className='text-center text-md-start mx-2 mt-1'>
                            <h6 className="my-0 text-uppercase text-white">56 Stores in Karnataka</h6>
                            <p className='mb-0 text-white' style={{ fontSize: '0.8rem' }}>Buy, repair and enquire</p>
                        </div>
                    </Col>
                    <Col md={3} sm={12} className='d-flex flex-column flex-md-row align-items-center justify-content-center my-4'>
                        <GoShieldCheck size={50} color='white'/>
                        <div className='text-center text-md-start mx-2 mt-1'>
                            <h6 className="my-0 text-uppercase text-white">SECURE PAYMENT</h6>
                            <p className='mb-0 text-white' style={{ fontSize: '0.8rem' }}>Secure payments through Razorpay</p>
                        </div>
                    </Col>
                    <Col md={3} sm={12} className='d-flex flex-column flex-md-row align-items-center justify-content-center my-4'>
                        <TfiHeadphoneAlt size={50} color='white' />
                        <div className='text-center text-md-start mx-2 mt-1'>
                            <h6 className="my-0 text-uppercase text-white">Customer SUPPORT</h6>
                            <p className='mb-0 text-white' style={{ fontSize: '0.8rem' }}>Call us for any enquiry</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Features;
