import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Badge } from 'react-bootstrap';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Products.css';
import ProductCard from '../ProductCard/ProductCard';

function RecentProducts() {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true); // Add a loading state
    const baseUrl = 'https://karstar.org/';

    const productsSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: false,
        arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
        ],
    };

    useEffect(() => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = sessionStorage.getItem('username');
        if (username == null) {
            if (sessionId !== null) {
                getRecentProd(sessionId, 'na');
            }
        } else {
            getRecentProd('na', username);
        }
    }, []);

    const getRecentProd = async (sessionId, username) => {
        try {
            const response = await axios.get(`https://karstar.org/api/client_api/recentProducts/?ipadd=${sessionId}&email=${username}`);
            setProducts((response.data).slice(0, 10));
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false); // Set loading to false after the fetch is complete
        }
    };

    return (
        <div>
            <Container>
                <section className='pt-3'>
                    <Row className="g-0">
                        <Col>
                            <h4 className='title py-2'>Recent Products</h4>
                            {loading ? (
                                <div>Loading...</div> // Display a loading message or spinner
                            ) : (
                                <div style={{ position: 'relative' }} className='products-scroll-container'>
                                    {/* <Slider
                                        ref={slider => {
                                            sliderRef = slider;
                                        }}
                                        {...productsSettings}
                                        className="top-categories pt-2"
                                    > */}
                                    {products.map((item, index) => (
                                        <div key={index} className="products-item">
                                            <ProductCard item={item} baseUrl={baseUrl} wishlist={item.in_wishlist} cart={item.in_cart} />
                                        </div>
                                    ))}
                                    {/* </Slider> */}
                                </div>
                            )}
                        </Col>
                    </Row>
                </section>
            </Container>
        </div>
    );
}

export default RecentProducts;
