import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Slider from 'react-slick';
import './Sliders.css';
import { Link } from 'react-router-dom';

const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
    arrows: false,
    centerMode: false,
};
function Sliders() {

    const [sliders, setSliders] = useState([])
    const baseURL = 'https://karstar.org/';
    useEffect(() => {
        getSliders();
    }, [])

    const getSliders = async () => {
        try {
            const response = await axios.get(`${baseURL}/api/client_api/Sliders/index.php`);
            setSliders(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const generateSlug = (name) => {
        return name.replace(/ /g, '-').replace(/[^\w-]+/g, '');
    };

    return (
        <div>
            <section>
                <div className="sliderDesktop mt-3">
                    <Container>
                        <Slider {...sliderSettings}>
                            {sliders && sliders
                                .sort((a, b) => {
                                    const priority = [
                                        "All Products", "Instant Geysers", "Portable Washing Machine", "Semi Automatic washing Machine",
                                        "High Pressure Washers", "Vaccum Cleaners", "Water Pumps", "Mixers and Wet Grinders",
                                        "Gas Stoves", "Chimneys", "Air coolers", "Shoe Racks", "Korai Mat", "Sofa cum Beds"
                                    ];

                                    // 1. Handle promotions first (slider_type === 'promotion')
                                    if (a.slider_type === 'promotion' && b.slider_type !== 'promotion') return -1;
                                    if (a.slider_type !== 'promotion' && b.slider_type === 'promotion') return 1;

                                    // 2. Handle product items (slider_type === 'product') should come first
                                    if (a.slider_type === 'product' && b.slider_type !== 'product') return -1;
                                    if (a.slider_type !== 'product' && b.slider_type === 'product') return 1;

                                    // 3. Now sort by collection name, using priority for predefined collections
                                    const aCollection = a.additional_data?.collection;
                                    const bCollection = b.additional_data?.collection;

                                    const aPriority = priority.indexOf(aCollection);
                                    const bPriority = priority.indexOf(bCollection);

                                    // Prioritize the categories in the priority array
                                    if (aPriority !== -1 && bPriority !== -1) {
                                        return aPriority - bPriority;
                                    }
                                    if (aPriority !== -1) return -1; // a has priority
                                    if (bPriority !== -1) return 1; // b has priority

                                    // Default alphabetical sorting for others
                                    return aCollection.localeCompare(bCollection);
                                })
                                .map((item, index) => {
                                    const linkUrl = item.slider_type === 'product'
                                        ? `/${item.slider_type}/${generateSlug(item.additional_data.product)}`
                                        : (item.slider_type === 'promotion'
                                            ? '/'
                                            : `/${item.slider_type}/${generateSlug(item.additional_data.collection)}`);

                                    return (
                                        <Link to={linkUrl} key={index} className="slide-item">
                                            <img
                                                src={baseURL + item.image_path}
                                                alt="Slide"
                                                className="sliderImages"
                                            />
                                        </Link>
                                    );
                                })}

                        </Slider>
                    </Container>
                </div>
                <div className="sliderMobile">
                    <Slider {...sliderSettings}>
                        {sliders && sliders.map((item, index) => (
                            <Link
                                to={`${item.slider_type === 'product'
                                    ? `/${item.slider_type}/${generateSlug(item.additional_data.product)}`
                                    : (item.slider_type == 'promotion' ? '/' : `/${item.slider_type}/${generateSlug(item.additional_data.collection)}`)
                                    }`}
                                key={index} className="slide-item">
                                <img
                                    src={baseURL + item.mobile_image_path}
                                    alt="Slide 1"
                                    className='sliderImages'
                                />
                            </Link>
                        ))}
                    </Slider>
                </div>
            </section>
        </div>
    )
}

export default Sliders
