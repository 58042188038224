import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Slider from 'react-slick'
import './Categories.css'
import { Link } from 'react-router-dom';

function Categories() {

    const categoriesSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 8,
        autoplay: false,
        autoplaySpeed: 2000,
        arrows: false,
        swipe: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 8,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 8,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                },
            },
        ],
    };

    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(true);
    const baseURL = 'https://karstar.org/'
    useEffect(() => {
        getCategories();
    }, [])

    const getCategories = async () => {
        try {
            const response = await axios.get(`${baseURL}/api/client_api/productCategories/index.php`);
            setCategories(response.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const generateSlug = (name) => {
        return name.replace(/ /g, '-').replace(/[^\w-]+/g, '');
    }

    return (
        <div>
            <section className='categoriesView' >
                <Container>
                    <Row className="mt-3">
                        <Col>
                            {/* <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <h4 className='title'>Shop by Categories</h4>
                                </div>
                                <div>
                                    <a href="/collections" className='text-black'>See All</a>
                                </div>
                            </div> */}
                            {loading ? (
                                <div>Loading...</div>
                            ) : (
                                <div className="categories-scroll-container">
                                    {categories
                                        .slice() // Create a shallow copy of the array
                                        .sort((a, b) => {
                                            const priority = ["All Products", "Instant Geysers", "Portable Washing Machine","Semi Automatic washing Machine", "High Pressure Washers", "Vaccum Cleaners", "Water Pumps", "Mixers and Wet Grinders","Gas Stoves", "Chimneys", "Air coolers", "Shoe Racks", "Korai Mat", "Sofa cum Beds"];
                                            const aPriority = priority.indexOf(a.name);
                                            const bPriority = priority.indexOf(b.name);

                                            // Prioritize the categories in the priority array
                                            if (aPriority !== -1 && bPriority !== -1) {
                                                return aPriority - bPriority;
                                            }
                                            if (aPriority !== -1) return -1; // a has priority
                                            if (bPriority !== -1) return 1; // b has priority

                                            // Default alphabetical sorting for others
                                            return a.name.localeCompare(b.name);
                                        })
                                        .map((category, index) => (
                                            <Link
                                                key={index}
                                                to={`/collection/${category.name}`}
                                                state={{ category }}
                                                className="text-decoration-none text-black category-item"
                                            >
                                                <div className="px-1 d-flex justify-content-center align-items-center flex-column">
                                                    <div
                                                        className="d-flex justify-content-center align-items-center categoriesContainer"
                                                    >
                                                        <img
                                                            src={baseURL + category.image_path}
                                                            alt={category.name}
                                                            className="categoriesImage"
                                                        />
                                                    </div>
                                                    <p className="text-center mt-2 categoriesText">
                                                        {category?.name?.replace(/-/g, ' ')}
                                                    </p>
                                                </div>
                                            </Link>
                                        ))}
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default Categories
