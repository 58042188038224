import React, { useContext } from 'react';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import './Wishlist.css';
import { WishlistContext } from '../../Utils/WishlistContext';
import RecentProducts from '../../components/Products/RecentProducts';
import Deals from '../../components/Products/Deals';
import NoWishlistItems from './NoWishlistItems';
import ProductCard from '../../components/ProductCard/ProductCard';
import { Link } from 'react-router-dom';
import RecentlyViewed from '../../components/Products/RecentlyViewed';

function Wishlist() {
    const { wishlistItems } = useContext(WishlistContext);
    const baseUrl = 'https://karstar.org/';
    return (
        <div>
            <section className='sectionView  mt-3'>
                <Container>
                    <div className='breadcrumb-container'>
                        <div className='breadcrumb'>
                            <a to='/' className='breadcrumb-item text-black'>Home</a>
                            <div className='breadcrumb-item text-black'>My Wishlist</div>
                        </div>
                    </div>
                    {wishlistItems.length > 0 ? (
                        <Row className="g-0">
                            {wishlistItems.map((item, index) => (
                                <Col xs={6} md={6} lg={2} key={index}>
                                    <ProductCard item={item} baseUrl={baseUrl} wishlist={item.in_wishlist} cart={item.in_cart} />
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <NoWishlistItems />
                    )}
                </Container>
                <RecentlyViewed />
                <RecentProducts />
                <Deals />
            </section>
        </div>
    );
}

export default Wishlist;
