import React, { useState, useContext } from 'react';
import { Card, Badge } from 'react-bootstrap';
import { IoStar } from 'react-icons/io5';
import { TbShoppingCartPlus, TbShoppingCartCheck, TbHeartPlus, TbHeartCheck } from "react-icons/tb";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from 'axios';
import { CartContext } from '../../Utils/CartContext';
import { WishlistContext } from '../../Utils/WishlistContext';
import { Link, useNavigate } from 'react-router-dom';
import './ProductCard.css';
import SweetAlert2 from 'react-sweetalert2';
import SecureLS from "secure-ls";

const ProductCard = ({ item, baseUrl, wishlist, cart }) => {
    const ls = new SecureLS({ encodingType: 'aes' });
    const navigate = useNavigate();
    const { addToCart } = useContext(CartContext);
    const { addToWishlist, removeFromWishlist, wishlistMessage } = useContext(WishlistContext);

    const [isAddedToCart, setIsAddedToCart] = useState(false);
    const [showLoadingIconCart, setShowLoadingIconCart] = useState(false);
    const [isAddedToWishlist, setIsAddedWishlist] = useState(wishlist === 1);
    const [showLoadingIconWishlist, setShowLoadingIconWishlist] = useState(false);
    const [swalProps, setSwalProps] = useState({});
    const selectedSize = useState(item.price[0].weight);
    const quantity = 1;

    const handleAddToCart = async () => {
        setShowLoadingIconCart(true);
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        const selectedPriceDetails = item.price[0];

        // console.log('id:',sessionId, 'email:',username);
        const success = username
            ? await addToCart('na', username, { selectedProdCode: selectedPriceDetails, quantity: quantity })
            : await addToCart(sessionId, 'na', { selectedProdCode: selectedPriceDetails, quantity: quantity });

        if (success) {
            // console.log(`Added ${item.name} to cart`);
            setShowLoadingIconCart(false);
            setSwalProps({
                show: true,
                title: item?.name,
                text: "Added to Cart!",
                icon: "success",
                confirmButtonText: "Okay",
                confirmButtonColor: "#cc0001",
            });
        } else {
            setShowLoadingIconCart(false);
            setSwalProps({
                show: true,
                title: item?.name,
                text: "Failed to add to cart!",
                icon: "error",
                confirmButtonText: "Try again.",
                confirmButtonColor: "#cc0001",
            });
        }
    };


    const handleAddToWishlist = async () => {
        setShowLoadingIconWishlist(true);
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        const selectedPriceDetails = item.price[0];
        const success = username
            ? await addToWishlist('na', username, { ...item, selectedProdCode: selectedPriceDetails.prodcode })
            : await addToWishlist(sessionId, 'na', { ...item, selectedProdCode: selectedPriceDetails.prodcode });
        if (success) {
            setShowLoadingIconWishlist(false);
            setIsAddedWishlist(true);
        } else {
            setShowLoadingIconWishlist(false);
            setIsAddedWishlist(false);
        }
    };


    // const handleRemoveFromWishlist = async () => {
    //     const sessionId = sessionStorage.getItem('sessionId');
    //     const username = ls.get('userEmail');
    //     const selectedPriceDetails = item.price[0];
    //     const success = username
    //         ? await addToWishlist('na', username, { ...item, selectedProdCode: selectedPriceDetails.prodcode })
    //         : await addToWishlist(sessionId, 'na', { ...item, selectedProdCode: selectedPriceDetails.prodcode });
    //     if (success) {
    //         setShowLoadingIconWishlist(false);
    //         setSwalProps({
    //             show: true,
    //             title: item?.name,
    //             text: wishlistMessage,
    //             icon: "success",
    //             confirmButtonText: "Okay",
    //             confirmButtonColor: "#cc0001",
    //         });
    //         setIsAddedWishlist(true);
    //     } else {
    //         setShowLoadingIconWishlist(false);
    //         setSwalProps({
    //             show: true,
    //             title: item?.name,
    //             text: wishlistMessage,
    //             icon: "error",
    //             confirmButtonText: "Try again.",
    //             confirmButtonColor: "#cc0001",
    //         });
    //     }
    // };

    const generateSlug = (name) => {
        return name.replace(/ /g, '-');
    }

    return (
        <>
            <Card className="custom-card bg-transparent me-1">
                <a href={`/product/${generateSlug(item?.name)}`} state={{ item }}>
                    <Card.Img
                        className='product-image'
                        src={baseUrl + item?.images[0]}
                    />
                </a>
                <div className='addToWishlist'>
                    {showLoadingIconWishlist ? (
                        <AiOutlineLoading3Quarters size={20} color='#EC1F29' className='rotate' />
                    ) : isAddedToWishlist ? (
                        <TbHeartCheck size={20} color='#CC0001' onClick={handleAddToWishlist} />
                    ) : (
                        <TbHeartPlus size={20} color='#CC0001' onClick={handleAddToWishlist} />
                    )}
                </div>
                <Card.Body className='px-0'>
                    <Card.Title className='product-name elipses text-start'>
                        <a href={`/product/${generateSlug(item.name)}`} className='text-decoration-none text-black'>
                            {item?.name}
                        </a>
                    </Card.Title>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div>
                            <div className='text-start'>
                                <IoStar color='#FFD700' /> {item.price[0].average_rating}
                                {item.price[0].delivered_sales_count > 0 && (
                                    <>
                                        {' | '}
                                        <Badge bg="secondary">{item.price[0].delivered_sales_count + ' ' + 'sold'}</Badge>
                                    </>
                                )}
                            </div>
                            <div>
                                <span className='me-2' style={{ fontSize: '0.8rem', fontWeight: 'bold' }} >₹ {item.price[0].discountedPrice}</span>
                                <span style={{ textDecoration: 'line-through', color: 'grey', fontSize: '0.9rem' }} >₹ {item.price[0].basePrice}</span>
                            </div>
                        </div>
                        <div className='addToCart'>
                            {showLoadingIconCart
                                ?
                                <AiOutlineLoading3Quarters size={20} color='#cc0001' className='rotate' />
                                :
                                <TbShoppingCartPlus size={20} color={item.price?.[0]?.inStock ? '#cc0001' : '#ddd'} onClick={() => item.price?.[0]?.inStock && handleAddToCart()} />
                            }
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <SweetAlert2
                didClose={() => {
                    setSwalProps({
                        show: false,
                    });
                }}
                {...swalProps}
            />
        </>
    );
};

export default ProductCard;
