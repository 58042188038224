import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Slider from 'react-slick';
import './Products.css';
import ProductCard from '../ProductCard/ProductCard';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';

function RecentlyViewed() {
    const [loading, setLoading] = useState(true);
    const [activeSlide, setActiveSlide] = useState(0);
    const [recentlyViewed, setRecentlyViewed] = useState([]);

    let sliderRef = useRef(null);
    const baseUrl = 'https://karstar.org/';

    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };

    const productsSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        swipe: true,
        slidesToShow: 5.4,
        slidesToScroll: 5,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: false,
        arrows: false,
        beforeChange: (current, next) => {
            setActiveSlide(next);
        },
        responsive: [
            {
                breakpoint: 1400, // xl (extra large)
                settings: {
                    slidesToShow: 5.4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 1200, // lg (large)
                settings: {
                    slidesToShow: 5.2,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 992, // md (medium)
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 768, // sm (small)
                settings: {
                    slidesToShow: 4.15,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 576, // xs (extra small)
                settings: {
                    slidesToShow: 2.35,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 486, // xxs (extra extra small)
                settings: {
                    slidesToShow: 2.35,
                    slidesToScroll: 2,
                },
            },
        ],
    };

    useEffect(() => {
        getRecentlyViewedItems();
    }, []);

    const getRecentlyViewedItems = () => {
        const recentlyViewedData = localStorage.getItem('recentlyViewed');
        if (recentlyViewedData) {
            const parsedData = JSON.parse(recentlyViewedData);
            if (parsedData.length > 0) {
                setRecentlyViewed(parsedData);
                setLoading(false);
            }
        }
    };

    // Only render if recentlyViewed has items
    if (loading || recentlyViewed.length === 0) {
        return null; // Don't render anything if there's no data or while loading
    }

    return (
        <div>
            <Container>
                <section className='pt-3'>
                    <Row className="g-0">
                        <Col>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <h4 className='title text-black py-2 text-capitalize'>
                                        <span className='gradient-text'>Recently </span>Viewed
                                    </h4>
                                </div>
                            </div>
                            <div style={{ position: 'relative' }} className='products-scroll-container'>
                                {/* <Slider
                                        ref={slider => {
                                            sliderRef = slider;
                                        }}
                                        {...productsSettings}
                                        className="top-categories pt-2"
                                    > */}
                                {recentlyViewed.map((item, index) => (
                                    <div key={index} className="products-item">
                                        <ProductCard item={item} baseUrl={baseUrl} wishlist={item.in_wishlist} cart={item.in_cart} />
                                    </div>
                                ))}
                                {/* </Slider> */}
                            </div>
                        </Col>
                    </Row>
                </section>
            </Container>
        </div>
    );
}

export default RecentlyViewed;
