import React from 'react'
import './Callus.css';
import { IoCall, IoCallOutline } from 'react-icons/io5';
function Callus() {
    return (
        <div>
            <a href="tel:+919036113046" className="float-call" target="_blank">
                <button type="submit" id="form-submit" className="call-button fw-bold "><IoCall color="#000000" size={20} className='me-2' />Call Us!</button>
            </a>
        </div>
    )
}

export default Callus
