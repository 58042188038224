import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card, ListGroup, Badge } from 'react-bootstrap';
import './Cart.css';
import { IoAddSharp, IoRemoveSharp, IoStar, IoTrashBin } from 'react-icons/io5';
import { WishlistContext } from '../../Utils/WishlistContext';
import { CartContext } from '../../Utils/CartContext';
import RecentProducts from '../../components/Products/RecentProducts';
import Deals from '../../components/Products/Deals';
import NoCartItems from './NoCartItems';
import { Link, useNavigate } from 'react-router-dom';
import razorPay from '../../assets/images/razorpay.png';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { TbHeartCheck, TbHeartPlus } from 'react-icons/tb';
import SecureLS from "secure-ls";
import RecentlyViewed from '../../components/Products/RecentlyViewed';

function Cart() {
  const baseUrl = 'https://karstar.org/';
  const ls = new SecureLS({ encodingType: 'aes' });
  const { removeFromCart, addToCart, cartItems, setCartItems, fetchCartItems } = useContext(CartContext);
  const { addToWishlist, removeFromWishlist } = useContext(WishlistContext);
  const [loadingStates, setLoadingStates] = useState({}); // To track loading states for each item

  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToTop();
  }, [navigate]);

  const handleIncreaseQuantity = async (item) => {
    const sessionId = sessionStorage.getItem('sessionId');
    const username = ls.get('userEmail');
    const selectedPriceDetails = item.price;
    const quantity = 1;
    const success = username
      ? await addToCart('na', username, { selectedProdCode: selectedPriceDetails, quantity })
      : await addToCart(sessionId, 'na', { selectedProdCode: selectedPriceDetails, quantity });

    if (success) {
      // console.log(`Added ${item.name} to cart`);
      fetchCartItems();
    } else {
      console.error('Failed to add to cart');
    }
  };

  const handleDecreaseQuantity = async (item) => {
    const sessionId = sessionStorage.getItem('sessionId');
    const username = ls.get('userEmail');
    const selectedPriceDetails = item.price;
    const quantity = 1;
    const success = username
      ? await removeFromCart('na', username, { selectedProdCode: selectedPriceDetails, quantity })
      : await removeFromCart(sessionId, 'na', { selectedProdCode: selectedPriceDetails, quantity });

    if (success) {
      // console.log(`Removed ${item.name} to cart`);
      fetchCartItems();
    } else {
      console.error('Failed to Remove from cart');
    }
  };

  const handleAddToWishlist = async (item) => {
    setLoadingStates((prev) => ({ ...prev, [item.id]: { ...prev[item.id], wishlist: true } }));

    const sessionId = sessionStorage.getItem('sessionId');
    const username = ls.get('userEmail');

    let success = false;
    if (username == null) {
      if (sessionId !== null) {
        success = await addToWishlist(sessionId, 'na', item);
      }
    } else {
      success = await addToWishlist('na', username, item);
    }

    if (success) {
      // Update the cartItems state
      setCartItems((prevItems) => prevItems.map((cartItem) =>
        cartItem.id === item.id ? { ...cartItem, in_wishlist: "1" } : cartItem
      ));
    }

    setLoadingStates((prev) => ({ ...prev, [item.id]: { ...prev[item.id], wishlist: false, added: true } }));
  };

  const handleRemoveFromWishlist = async (item) => {
    setLoadingStates((prev) => ({ ...prev, [item.id]: { ...prev[item.id], wishlist: true } }));

    const sessionId = sessionStorage.getItem('sessionId');
    const username = ls.get('userEmail');

    let success = false;
    if (username == null) {
      if (sessionId !== null) {
        success = await removeFromWishlist(sessionId, 'na', item);
      }
    } else {
      success = await removeFromWishlist('na', username, item);
    }

    if (success) {
      // Update the cartItems state
      fetchCartItems();
    }

    setLoadingStates((prev) => ({ ...prev, [item.id]: { ...prev[item.id], wishlist: false, added: false } }));
  };

  // Calculate totals
  const totalQuantity = cartItems.reduce((total, item) => total + parseInt(item.qty), 0);
  const totalMRP = cartItems.reduce((total, item) => total + parseFloat(item.price.basePrice) * parseInt(item.qty), 0);
  const totalDiscount = cartItems.reduce((total, item) => total + (parseFloat(item.price.basePrice) - parseFloat(item.price.discountedPrice)) * parseInt(item.qty), 0);
  const totalAmount = cartItems.reduce((total, item) => total + parseFloat(item.price.discountedPrice) * parseInt(item.qty), 0);

  const platformFee = 20;
  const shippingFee = 79;

  const Breadcrumb = ({ product }) => {
    return (
      <div className='breadcrumb-container'>
        <div className='breadcrumb'>
          <Link to='/' className='breadcrumb-item text-black'>Home</Link>
          <div className='breadcrumb-item text-black'>cart</div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <section className='sectionView  mt-3'>
        <Container>
          <Breadcrumb product={cartItems} />
          {cartItems.length > 0 ? (
            <Row>
              <Col md={7}>
                {cartItems.map(item => (
                  <Card key={item.id} className="card border shadow-none mb-2">
                    <Card.Body>
                      <Row className="d-flex align-items-start">
                        <Col xs={6} s={6} md={6} lg={4} className="me-0">
                          <img src={baseUrl + item.images[0]} alt={item.name} className='avatar-lg rounded' style={{ zIndex: 1 }} />
                        </Col>
                        <Col xs={6} s={6} md={6} lg={8} className="d-flex flex-column align-self-start overflow-hidden">
                          <div>
                            <p className='text-muted text-start mb-0'>karstar</p>
                            <h5 className="text-truncate  text-start fr1"><a href="#" className="text-dark">{item.name}</a></h5>
                            <div className='text-start'>
                              <div>
                                <span className='price-before me-2'>₹ {item?.price.basePrice}</span>
                                <span className='prod-price'>₹ {item?.price.discountedPrice}</span> <br />
                              </div>
                            </div>
                          </div>
                          <ListGroup horizontal className="list-inline mb-0 font-size-16 mt-2">
                            <div className="quantity-selector mb-3 d-flex">
                              <Button onClick={() => handleDecreaseQuantity(item)} className='bg-black text-white border-0 rounded-start-2 rounded-end-0'>
                                <IoRemoveSharp color='white' />
                              </Button>
                              <span className="align-self-center text-white bg-black px-4 py-2 fw-bold">{item.qty}</span>
                              <Button onClick={() => handleIncreaseQuantity(item)} className='bg-black text-white border-0 rounded-end-2 rounded-start-0'>
                                <IoAddSharp color='white' />
                              </Button>
                            </div>
                            {/* <ListGroup.Item as="li" className="list-inline-item">
                              {loadingStates[item.id]?.wishlist ? (
                                <AiOutlineLoading3Quarters size={20} color='#EC1F29' className='rotate' />
                              ) : (item?.in_wishlist == 1 || loadingStates[item.id]?.added === true) ? (
                                <TbHeartCheck size={20} color='#CC0001' onClick={() => handleRemoveFromWishlist(item)} />
                              ) : (
                                <TbHeartPlus size={20} color='#CC0001' onClick={() => handleAddToWishlist(item)} />
                              )}
                            </ListGroup.Item> */}
                          </ListGroup>
                          <span className='prod-price text-start'>Product Total : ₹ {item?.price.discountedPrice * item?.qty}</span>
                        </Col>
                        {/* <Col xs={3} className='d-flex justify-content-center align-items-center'>
                          <div >
                            <span className='prod-price'>₹ {item?.price.discountedPrice * item?.qty}</span>
                          </div>
                        </Col> */}
                      </Row>
                    </Card.Body>
                  </Card>
                ))}
              </Col>
              <Col md={5}>
                <Card className='mb-2' style={{ position: 'sticky', top: '18vh' }}>
                  <Card.Body>
                    <h5 className='fr1 text-start'>Order Summary ({totalQuantity} item{totalQuantity > 1 ? 's' : ''})</h5>
                    <Row>
                      <Col className='text-start'>Subtotal</Col>
                      <Col className="text-end mb-2">&#8377;{totalMRP.toFixed(2)}</Col>
                    </Row>
                    <Row>
                      <Col className='text-start'>Discount</Col>
                      <Col className="text-end mb-2">&#8377;{totalDiscount.toFixed(2)}</Col>
                    </Row>
                    <Row>
                      <Col className='text-start'>Shipping</Col>
                      <Col className="text-end">At Checkout</Col>
                    </Row>
                    <hr />
                    <Row className="mt-3">
                      <Col className='text-start'>Total Payable</Col>
                      <Col className="text-end mb-2"><strong>&#8377;{totalAmount.toFixed(2)}</strong></Col>
                    </Row>
                    <h5 className='fr1 text-start'>Payment</h5>
                    <div className='text-start'>All transactions are secure and encrypted.</div>
                    <Card className="my-3 p-3">
                      <Card.Title className='fr1'>Razorpay Secure (UPI, Cards, Wallets, NetBanking)</Card.Title>
                      <Card.Text>
                        <img src={razorPay} alt="Razorpay" style={{ width: '100%' }} />
                      </Card.Text>
                    </Card>
                    <Link to={`/checkout`} state={{ item: cartItems, isBuyNow: 'no', isCart: 'yes' }} className='btn btn-danger w-100 rounded-pill py-3'>
                      Proceed to Checkout
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : (
            <NoCartItems />
          )}
        </Container>
        <RecentlyViewed />
        <RecentProducts />
        <Deals />
      </section>
    </div>
  );
}

export default Cart;
